export default {
    "props": {
        "fullScreenProps": "md",
        "slideDialogProps": {
            "direction": "up",
        },
        "dialogProps": {
        },
        "dialogTitleProps": {
            "align": "center"
        },
        "dialogCloseProps": {
            "disableRipple": true,
            "edge": "end",
            "size": "small",
        },
        "dialogContentProps": {
        }
    },
    "sx": (theme) => ({
        // "display": "flex",
        // "alignItems": "flex-end",
        "& .dialogDefault-container": {
            // [theme.breakpoints.down('md')]: {
                // "position": "relative",
                // // "bottom": 0,
                // // "display": "flex",
                // // "alignItems": "flex-end",
                // "width": "100%",
                // "height": "100%",
            // },
        },
        "& .dialogDefault-paper": {
            [theme.breakpoints.up('md')]: {
                "borderRadius": "6px",
            },
            "& .MuiDialogTitle-root": { // This is the dialog title container
                "& .MuiTypography-root": { // This is the title
                    // "maxWidth": "82%",
                    "marginRight": "20px",
                    "textAlign": "center",
                },
            },
        },
        "& .dialogDefault-paperFullScreen": {
            // "position": "absolute",
            // "bottom": 0,
            // "left": 0,
            // "height": "auto",
            "borderRadius": "6px 6px 0 0",
        },
        "& .dialogDefault-close": {
            "position": "absolute",
            "top": "20px",
            "right": "20px",
            "width": "24px",
            "height": "24px",
            "zIndex": 9999,
        },
        "& .dialogDefault-content, & .MuiDialogTitle-root + .dialogDefault-content": {
            "padding": theme.spacing(3.75, 5),
            [theme.breakpoints.up('md')]: {
                "padding": theme.spacing(3.75, 10),
            }
        }
    })
}
