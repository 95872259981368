import React from "react";
import { get } from "lodash";
// import { useLocation } from "@reach/router";
import { isSold } from '@myaccount/property/utils';

import IconButton from "@mui/material/IconButton"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import FavoriteIcon from "@mui/icons-material/Favorite"

import { useUserObjectState, useAuthState } from "@myaccountServices"

import { doRedirect } from "@myaccount/utils"
import { MYACCOUNT_LOGIN } from "@myaccountUrls";
import { trackSaveProperty } from "@myaccount/helper/eventTracking";

export const SaveProperty = props => {

    const { state, services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
    // const location = useLocation();

    if (isSold(props?.flag?.status, props?.property?.status)) {
        return <></>
    }

    const handleSaveProperty = (params, status, type) => {
        let saveparams = {
            params: params,
            type: type,
            state: status,
        }

        if (!isAuthenticated) {
            // save params to send auth params
            authServices.storeData('auth_params', saveparams);
            authServices.storeData('auth_return', window.location.pathname);
            doRedirect(MYACCOUNT_LOGIN);
        } else {
            services.createUserobject(saveparams)
            trackSaveProperty({
                eventLabel: "Create"
            });
        }
    }

    const isSaved = (params, type) => {
        if (!isAuthenticated) return false

        if (state?.userObjects)
            return services.isSaved(state.userObjects, params, props.type)
    }

    let params = props.searchParams ? props.searchParams : {id: props.property.objectID || props.property.id};

    let isSavedItem

    if (state?.userObjects) {
        isSavedItem = isSaved(params)
    }

    return (
        <IconButton
            aria-label="save property"
            color="inherit"
            onClick={() => {
                handleSaveProperty(params, !isSavedItem, props.type)
            }}
            className="save-property-component"
        >
            <>
                {isSavedItem && <FavoriteIcon />}
                {!isSavedItem && <FavoriteBorderIcon />}
            </>
        </IconButton>
    );
}

export default SaveProperty;
