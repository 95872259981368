import React from "react";
import { useLocation } from "@reach/router";
import { withSubtheme } from '@starberryComponentsMui';
import Button from "@mui/material/Button";
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import defaults from './defaults';
import { FormStoreProvider, useFormState, useAuthState } from "@myaccountServices";
import { doRedirect } from "@myaccount/utils"
import { MYACCOUNT_LOGIN } from "@myaccountUrls";
import CircularProgress from '@myaccount/progress/CircularProgress'
import NewDocumentBtn from './newBtn';
import AddIcon from '@mui/icons-material/Add';

const MakeOfferBtnWrap = withSubtheme(props => {
    const {
        btnLabelProps,
        modalTitle,
        btnLabel,
        btnProps,
        property,
        reloadDataFun,
        identityChecks,
        showAddIcon,
        // link,
    } = props

    const { state, services } = useFormState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()
    const location = useLocation();

    const label = btnLabel ? btnLabel : btnLabelProps;

    const [open, setOpen] = React.useState(false);
    const [canReloadData, setCanReloadData] = React.useState(false);

    React.useEffect(() => {
        if (identityChecks && !state?.allowedActions) {
            services.getActionAllowed({property_id:property.crm_id})
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClickOpen = () => {
        if (!isAuthenticated) {
            authServices.storeData('auth_return', location.pathname);
            doRedirect(MYACCOUNT_LOGIN);
        } else {
            setOpen(true);
        }
    };

    // if form success then reloadData
    if (state.success && !canReloadData)
        setCanReloadData(true);

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (reloadDataFun && canReloadData) {
            reloadDataFun();
        }
        setOpen(false);
    };

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        dialogContent = <DialogChildren
            handleClose={handleClose} property={property}
        />
    }

    if (identityChecks && state?.allowedActions && !state?.allowedActions?.approvedToBid) {
        const allowedActions = state?.allowedActions;
        let btnDisabled = false;
        let toolTip = '';
        if (!allowedActions?.hasViewing || (allowedActions?.hasIdentitycheck && !allowedActions?.approvedToBid))
            btnDisabled = true;

        if (allowedActions?.hasIdentitycheck && !allowedActions?.approvedToBid)
            toolTip = 'Your proof of funds is under review.';
        if (!allowedActions?.hasViewing)
            toolTip = 'You should have viewed this property to bid. Click on request a viewing.';

        return (
            <NewDocumentBtn
                altBtnLabel="Request to bid"
                altModalTitle="Upload Proof of Funds"
                altPlusBtn={showAddIcon}
                showAddIcon={showAddIcon}
                btnDisabled={btnDisabled}
                toolTip={toolTip}
                postActionData={{
                    allowedActions: {
                        ...state?.allowedActions,
                        hasIdentitycheck: true
                    }
                }}
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
            />
        )
    }

    if (identityChecks && !state?.allowedActions && state?.loading)
        return <CircularProgress altClass="padding-b-20" />;

    if (identityChecks && !state?.allowedActions)
        return null;

    return(
        <>
            <Button {...btnProps} className={`btn contact-btn ${showAddIcon ? 'btn-add' : ''}`} onClick={handleClickOpen}>
                {showAddIcon && <AddIcon sx={{ mr: 1 }} /> }
                {label}
            </Button>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                property={property}
                children={dialogContent}
                loading={state.loading}
                title={modalTitle ? modalTitle : "Make an Offer"}
            />
        </>
    )
}, 'makeOfferBtn', defaults)

const MakeOfferBtn = (props) => {
    return (
        <FormStoreProvider>
            <MakeOfferBtnWrap {...props} />
        </FormStoreProvider>
    )
}

export default MakeOfferBtn;
