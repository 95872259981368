import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import defaults from './defaults';

const ActionDialogDefault = withSubtheme(props => {
    const {
        theme,
        className,
        open,
        handleClose,
        title,
        maxDialogWidth,
        children,
        fullScreenProps,
        slideDialogProps,
        dialogProps,
        dialogTitleProps,
        dialogCloseProps,
        dialogContentProps,
        loading,
    } = props

    const fullScreenProp = fullScreenProps ? useMediaQuery(theme.breakpoints.down(fullScreenProps)) : false;

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide {...slideDialogProps} ref={ref} {...props} />;
    });

    let onCloseProps = { onClose:handleClose }
    if (loading)
        onCloseProps = {}

    return (
        <Dialog
            TransitionComponent={Transition}
            fullScreen={fullScreenProp}
            fullWidth={!!maxDialogWidth}
            maxWidth={maxDialogWidth ? maxDialogWidth : 'sm'}
            scroll="body"
            {...onCloseProps}
            aria-labelledby="more options"
            open={open}
            {...dialogProps}
            classes={{
                root: className,
                container: "dialogDefault-container",
                paper: "dialogDefault-paper",
                paperFullScreen: "dialogDefault-paperFullScreen"
            }}
        >
            {!loading && (
                <>
                    {title ? (
                        <DialogTitle {...dialogTitleProps}>
                            {title}
                            {handleClose && (
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    {...dialogCloseProps}
                                    classes={{root: "dialogDefault-close"}}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </DialogTitle>
                    ) : (
                        <>
                            {handleClose && (
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    {...dialogCloseProps}
                                    classes={{root: "dialogDefault-close"}}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </>
                    )}
                </>
            )}
            <DialogContent
                {...dialogContentProps}
                classes={{root: "dialogDefault-content"}}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
}, 'dialogDefault', defaults);

export default ActionDialogDefault
