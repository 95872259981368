import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields, isRequiredFieldHasValues } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/createTenacy";
import { trackCreateTenancy, getUserRole } from "@myaccount/helper/eventTracking";

import defaults from "../reportFaultBtn/dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {
    const { className } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = event => {
        event.preventDefault()
        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false && isRequiredFieldHasValues(formWithValue)) {
            const params = {
                property_id: props?.property?.crm_id,
                search_type: props?.property?.search_type,
                negotiator_id: props?.property?.negotiator_id,
                form_name: "tenancy",
                // step: step,
                successMessage: "Tenancy Offer Posted Successfully",
                // ...actionParams.formParams,
                trackData: () => {
                    trackCreateTenancy({
                        eventLabel: `Create - Property - ${props?.property?.crm_id}`,
                        eventRole: getUserRole(props?.property)
                    })
                }
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
